<template>
  <div  class="px-15px px-lg-25px">
    <section class="py-5">
      <div class="container">
        <div class="d-flex align-items-start">
          <div class="aiz-user-panel">
            <div class="row gutters-10">
              <div class="col-md-4 mx-auto mb-3" >
                <div class="bg-grad-1 text-white rounded-lg overflow-hidden">
                  <span class="size-30px rounded-circle mx-auto bg-soft-primary d-flex align-items-center justify-content-center mt-3">
                    <i class="las la-dollar-sign la-2x text-white"></i>
                  </span>
                  <div class="px-3 pt-3 pb-3">
                    <div class="h4 fw-700 text-center">0.00</div>
                    <div class="opacity-50 text-center">{{ $t('hui-yuan-yu-e') }}</div>
                  </div>
                </div>
              </div>
              <div class="col-md-4 mx-auto mb-3" >
                <a >
                  <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition">
                    <span class="size-60px rounded-circle mx-auto bg-secondary d-flex align-items-center justify-content-center mb-3">
                      <i class="las la-dharmachakra la-3x text-white"></i>
                    </span>
                    <div class="fs-18 text-primary">{{ $t('zhi-fu-she-zhi') }}</div>
                  </div>
                </a>
              </div>
                <div class="col-md-4 mx-auto mb-3" >
                  <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition" >
                    <span class="size-60px rounded-circle mx-auto bg-secondary d-flex align-items-center justify-content-center mb-3">
                      <i class="las la-plus la-3x text-white"></i>
                    </span>
                    <div class="fs-18 text-primary">{{ $t('ti-kuan-qing-qiu') }}</div>
                  </div>
                </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="card">
                  <div class="form-box-content p-3">
                    <div class="form-group">
                      <textarea id="referral_code_url" class="form-control" readonly type="text" >{{ app_promotion }}/#/shop/create?code={{userInfo.InvitationCode}}</textarea>
                    </div>
                    <button type=button id="ref-cpurl-btn" class="btn btn-primary float-right copy-icon" @click="copyData">{{ $t('fu-zhi-di-zhi') }}</button>
                  </div>
                </div>
              </div>
            </div>
            <br>

            <div class="card">
              <form class="" id="sort_blogs" action="" method="GET">
                <div class="card-header row">
                  <div class="col text-center text-md-left">
                    <h5 class="mb-md-0 h6">{{ $t('hui-yuan-tong-ji') }}</h5>
                  </div>
                  <div class="col-md-5 col-xl-4">
                    <div class="input-group mb-0">
                      <a-select class="form-control clear" >
                        <a-select-option v-for="(item, i) in optionList" :key="i" :value="item.value">{{item.label}}</a-select-option>
                      </a-select>
                      <button class="btn btn-primary input-group-append" type="submit">{{ $t('shai-xuan') }}</button>
                    </div>
                  </div>
                </div>
              </form>

              <div class="card-body">
                <div class="row gutters-10">
                  <div class="col-md-3 mx-auto mb-3">
                    <a >
                      <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition">
                        <span class="size-60px mx-auto d-flex align-items-center justify-content-center mb-3">
                          <span class="la-3x text-white" style="color: #007bff !important;">
                            0
                          </span>
                        </span>
                        <div class="fs-18 text-primary">{{ $t('zong-mai-jia') }}</div>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-3 mx-auto mb-3" >
                    <a>
                      <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition">
                        <span class="size-60px mx-auto d-flex align-items-center justify-content-center mb-3">
                          <span class="la-3x text-white" style="color: #007bff !important;">
                            0
                          </span>
                        </span>
                        <div class="fs-18 text-primary">{{ $t('zong-ding-dan') }}</div>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-3 mx-auto mb-3" >
                    <a >
                      <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition">
                        <span class="size-60px mx-auto d-flex align-items-center justify-content-center mb-3">
                          <span class="la-3x text-white" style="color: #007bff !important;">
                            0
                          </span>
                        </span>
                        <div class="fs-18 text-primary">{{ $t('zong-jin-e') }}</div>
                      </div>
                    </a>
                  </div>
                  <div class="col-md-3 mx-auto mb-3" >
                    <a>
                      <div class="p-3 rounded mb-3 c-pointer text-center bg-white shadow-sm hov-shadow-lg has-transition">
                        <span class="size-60px mx-auto d-flex align-items-center justify-content-center mb-3">
                          <span class="la-3x text-white" style="color: #007bff !important;">
                            0
                          </span>
                        </span>
                        <div class="fs-18 text-primary">{{ $t('zong-yong-jin') }}</div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="card">
              <div class="card-body">
                <table class="table  mb-0" :class="tableData.length == 0 ? ' footable aiz-table' : ''">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{{ $t('dian-pu-ming-cheng') }}</th>
                      <th data-breakpoints="lg">{{ $t('ding-dan-hao') }}</th>
                      <th data-breakpoints="lg">{{ $t('yong-jin') }}</th>
                      <th data-breakpoints="lg">{{ $t('deng-ji') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in tableData " :key="i">
                      <td>{{ i+ 1 }}</td>
                      <td>{{ $t('li-si-de-dian-pu') }}</td>
                      <td>1231312312312</td>
                      <td>13.33</td>
                      <td>1</td>
                    </tr>
                    <tr class="footable-empty" v-if="tableData.length == 0"><td colspan="5">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="card">
              <div class="card-header">
                <h5 class="mb-0 h6">{{ $t('hui-yuan-shou-ru-li-shi') }}</h5>
              </div>
              <div class="card-body">
                <table class="table  mb-0"  :class="tableData.length == 0 ? ' footable aiz-table' : ''">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{{ $t('tui-jian-yong-hu') }}</th>
                      <th>{{ $t('jin-e') }}</th>
                      <th data-breakpoints="lg">{{ $t('ding-dan-hao-0') }}</th>
                      <th data-breakpoints="lg">{{ $t('tui-jian-lei-xing') }}</th>
                      <th data-breakpoints="lg">{{ $t('chan-pin') }}</th>
                      <th data-breakpoints="lg">{{ $t('ri-qi') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in tableData" :key="i">
                      <td>{{i + 1}}</td>
                      <td>
                        {{item.name}}
                      </td>
                      <td>$10.03</td>
                      <td>
                        1231231283
                      </td>
                      <td>{{item.type}}/td>
                      <td>
                        <div v-for="(product, index) in item.list" :key="index">{{product.Name}}</div>
                      </td>
                      <td>{{item.CreateAt}}</td>
                    </tr>
                    <tr class="footable-empty" v-if="tableData.length == 0"><td colspan="7">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
                  </tbody>
                </table>
                <div class="aiz-pagination mt-2" v-if="page.total > 1">
                  <pageBar :totalPage="page.total" :page="page.page"></pageBar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import pageBar from '@/components/page'
import { resetPrice } from '@/utils/common'
import {configList, userInfo} from "@/api/user";
import Clipboard from "clipboard";

export default {
  components: {
    pageBar
  },
  data() {
    return {
      app_promotion: '',
      userInfo: {},
      optionList: [
        {
          value: '',
          label: this.$t('quan-bu')
        },
        {
          value: '1',
          label: this.$t('jin-tian')
        },
        {
          value: '2',
          label: this.$t('zui-jin-7-tian')
        },
        {
          value: '3',
          label: this.$t('zui-jin-30-tian')
        },
      ],
      tableData: [],
      page: {
        page: 1,
        total: 1,
        limit: 10
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    resetPrice,
    copyData() {
      let data = `${this.app_promotion}/#/shop/create?code=${this.userInfo.InvitationCode}`
      const clipboard = new Clipboard('.copy-icon', {text: () => data});
      this.$toast.fail(this.$t('Copy successful'))
    },
    init(){
      configList().then(res => {
        let data = res.data
        for(var i in data) {
        if(data[i].Name == 'app_promotion') {
            this.app_promotion = data[i].Value
          }
        }
      })
      userInfo().then(res => {
        if (res.code == 0) {
          this.userInfo = res.data
          console.log("userInfo:",this.userInfo)
        }
      })
    }
  }
}
</script>